body {
  margin: 0;
  font-family: 'Petit Formal Script', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('./assets/backgroung.png'); /* Set the background image */
  background-size: cover; /* Scale the background image to cover the entire body */
  background-repeat: no-repeat; /* Prevent the background image from repeating */
  background-attachment: fixed;
  background-position: center; /* Center the background image vertically and horizontally */
  height: 100vh;
  width: 100%;
  background-color: #333333; /* Dark background color */
  color: #ffffff; /* Light text color */
}
code {
  font-family: 'Petit Formal Script', cursive;
  font-weight: bold;
}

.white{
  color: #ffffff;
  fill: #ffffff;
}

@keyframes fadeInText {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}




/* Apply dark mode styles when user prefers a dark color scheme */
@media (prefers-color-scheme: dark) {
  /* Import your dark mode styles */
  @import 'dark-mode.css';
}